
/* eslint-disable complexity */
import {
  defineComponent,
  toRefs,
  PropType,
  reactive,
  nextTick,
  ref,
  watch,
  toRef,
  computed,
  Ref
} from 'vue'
import StopSearch from '@/components/StopSearch.vue'
import ClearableInput from '@/components/ClearableInput.vue'
import Modal from '@/components/Modal.vue'
import WheelchairModal from '@/components/WheelchairModal.vue'
import WheelchairFareClassSelector from '@/components/WheelchairFareClassSelector.vue'
import JourneyDatePicker from '@/components/DatePicker/JourneyDatePicker.vue'
import JourneyPassengerSelect from '@/components/JourneyPassengerSelect.vue'
import { BusStop } from '@/models/BusStop'
import dayjs from 'dayjs'
import router from '@/router'
import { urlEncodeFares } from '@/helpers/fares'
import useLocalStorage from '@/composables/useLocalStorage'
import { Fares } from '@/models/FareClass'
import { sendExponeaCheckoutEvent } from '@/helpers/exponeaHelper'
import { useStops } from '@/composables/useStops'
import SwapIcon from '@/components/vectors/SwapIcon.vue'

interface StopData {
  visible: boolean;
  element: null | typeof StopSearch;
  label: string;
  stop: Ref<undefined | BusStop>;
}

interface SearchParams {
  oneWay: boolean;
  promoCode: string;
  departureDate: string;
  returnDate: string;
  originStop?: BusStop;
  destinationStop?: BusStop;
  wheelchairPassenger: boolean;
  WheelchairPassengerFareClass: string;
  [x: string]: unknown;
}

export default defineComponent({
  name: 'JourneySearch',
  components: {
    StopSearch,
    JourneyDatePicker,
    JourneyPassengerSelect,
    ClearableInput,
    SwapIcon,
    WheelchairModal,
    Modal,
    WheelchairFareClassSelector
  },
  props: {
    destination: Object as PropType<BusStop>,
    origin: Object as PropType<BusStop>,
    passengers: Object as PropType<Fares>,
    departureDate: String,
    returnDate: String,
    oneWay: Object as PropType<boolean>
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const {
      destination,
      origin,
      passengers,
      departureDate,
      returnDate,
      oneWay
    } = toRefs(props)
    const valid = ref(false)
    const showWheelchairModal = ref(false)
    const showWheelchairTypeSelector = ref(false)
    const { stops, errorLoadingStops: error } = useStops()
    const dataset = useLocalStorage('searchData', {
      oneWay: false,
      promoCode: '',
      departureDate: dayjs().format('YYYY-MM-DD'),
      returnDate: dayjs().format('YYYY-MM-DD'),
      originStop: undefined,
      destinationStop: undefined,
      wheelchairPassenger: false,
      WheelchairPassengerFareClass: ''
    } as SearchParams)

    const passengersCount = computed(() => {
      let total = 0
      for (const [, value] of Object.entries(fares)) {
        total = total + (value || 0)
      }
      return total
    })

    if (dayjs(dataset.departureDate).isBefore(dayjs())) {
      dataset.departureDate = dayjs().format('YYYY-MM-DD')
    }

    if (dayjs(dataset.returnDate).isBefore(dayjs(dataset.departureDate))) {
      dataset.returnDate = dataset.departureDate
    }
    dataset.destinationStop = destination?.value ?? dataset.destinationStop
    dataset.originStop = origin?.value ?? dataset.originStop
    dataset.departureDate = departureDate?.value ?? dataset.departureDate
    dataset.returnDate = returnDate?.value ?? dataset.returnDate
    dataset.returnDate = dataset.returnDate > dataset.departureDate
      ? dataset.returnDate
      : dataset.departureDate
    dataset.oneWay = oneWay?.value ?? dataset.oneWay
    const stopState = reactive<{ [index: string]: StopData }>({
      origin: {
        visible: false,
        element: null,
        label: 'Where are you travelling from?',
        stop: toRef(dataset, 'originStop')
      },
      destination: {
        visible: false,
        element: null,
        label: 'Where would you like to go?',
        stop: toRef(dataset, 'destinationStop')
      }
    })
    const fares = reactive({
      adult: passengers?.value?.adult || 0,
      child: passengers?.value?.child || 0,
      youngAdult: passengers?.value?.youngAdult || 0,
      student: passengers?.value?.student || 0,
      dsp: passengers?.value?.dsp || 0,
      seatReservation: passengers?.value?.seatReservation || 0
    } as Fares)

    const showSearch = (key: string) => {
      stopState[key].visible = true
      nextTick(() => stopState[key].element?.focus())
    }

    const checkDatePresence = () =>
      dataset.departureDate && (dataset.returnDate || dataset.oneWay)

    const checkValidity = () => {
      if (
        checkDatePresence() &&
        stopState.origin.stop?.BusStopId &&
        stopState.destination.stop?.BusStopId &&
        Object.values(fares).some(fare => fare)
      ) {
        valid.value = true
        return
      }

      valid.value = false
    }
    const confirmedWheelchairFareTypes = (selected: string) => {
      showWheelchairTypeSelector.value = false
      dataset.WheelchairPassengerFareClass = selected
      search(true)
    }
    const search = (verified: boolean) => {
      if (!verified) {
        if (dataset.wheelchairPassenger &&
          (!dataset.originStop?.IsWheelchairAccessible ||
            !dataset.destinationStop?.IsWheelchairAccessible)
        ) {
          showWheelchairModal.value = true
          return
        }

        if (dataset.wheelchairPassenger) {
          const multiplesFares = Object.values(fares).filter(fare => fare && fare > 0)
          if (multiplesFares.length > 1) {
            showWheelchairTypeSelector.value = true
            return
          } else {
            const uniqueFare = Object.entries(fares).find(fare => fare[1] && fare[1] > 0)
            if (uniqueFare) {
              dataset.WheelchairPassengerFareClass = uniqueFare[0].toString()
            }
          }
        }
      }

      if (!dataset.wheelchairPassenger) {
        dataset.WheelchairPassengerFareClass = ''
      }
      sendExponeaCheckoutEvent(
        {
          step: 'journey-search',
          basket_code: '',
          journey_origin_stop: stopState.origin.stop?.BusStopName,
          journey_destination_stop: stopState.destination.stop?.BusStopName,
          origin_stop: stopState.origin.stop?.BusStopName,
          destination_stop: stopState.destination.stop?.BusStopName,
          departure_date: dataset.departureDate,
          return_journey: !dataset.oneWay,
          origin_stop_country: 'Ireland',
          origin_stop_code: stopState.origin.stop?.BusStopId.toString() || '',
          origin_stop_city: stopState.origin.stop?.CityName,
          destination_stop_country: 'Ireland',
          destination_stop_city: stopState.destination.stop?.CityName,
          destination_stop_code: stopState.destination.stop?.BusStopId.toString() || '',
          Timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          bus_company: 'Bus Éireann',
          line_brand: 'Expressway',
          currency: 'EUR',
          quantity: passengersCount.value
        }
      )
      router.push({
        name: 'Select Journey',
        query: {
          originId: stopState.origin.stop?.BusStopId.toString() || '',
          destinationId: stopState.destination.stop?.BusStopId.toString() || '',
          departureDate: dataset.departureDate,
          returnDate: dataset.returnDate || '',
          promoCode: dataset.promoCode,
          oneWay: dataset.oneWay.toString(),
          fares: urlEncodeFares(fares),
          wheelchair: dataset.wheelchairPassenger.toString(),
          wheelchairPassengerFareClass: dataset.WheelchairPassengerFareClass
        }
      })
    }
    const passengerNumber = computed(() =>
      Object.keys(fares).reduce(
        (passengersNumber: number, key: string): number =>
          passengersNumber + (fares[key] || 0),
        0
      )
    )

    const passengersText = computed(
      (): string =>
        `(${passengerNumber.value} passenger${
          passengerNumber.value !== 1 ? 's' : ''
        })`
    )

    const filteredStops = computed(() => {
      if (!dataset.wheelchairPassenger) return stops.value

      return stops?.value?.filter(stop => stop.IsWheelchairAccessible)
    })

    watch(
      [
        dataset,
        () => stopState.origin.stop,
        () => stopState.destination.stop,
        fares
      ],
      checkValidity,
      { deep: true }
    )

    const continueWheelchairSelection = () => {
      showWheelchairModal.value = false
      if (!dataset.originStop?.IsWheelchairAccessible) {
        dataset.originStop = undefined
      }
      if (!dataset.destinationStop?.IsWheelchairAccessible) {
        dataset.destinationStop = undefined
      }
    }
    checkValidity()

    const closeStopSearch = (leg: StopData) => {
      leg.visible = false
      window.scrollTo(0, 0)
    }

    const countryFilter = (name: string) => {
      if (name === 'origin') {
        return stopState.destination.stop?.CountryId === 52 ? 52 : null
      } else {
        return stopState.origin.stop?.CountryId === 52 ? 52 : null
      }
    }

    const euroTrip = computed(() =>
      stopState.destination.stop?.CountryId === 52 ||
        stopState.origin.stop?.CountryId === 52)

    const switchDirection = () => {
      const newOrigin = stopState.destination.stop
      stopState.destination.stop = stopState.origin.stop
      stopState.origin.stop = newOrigin
    }
    return {
      switchDirection,
      closeStopSearch,
      confirmedWheelchairFareTypes,
      continueWheelchairSelection,
      filteredStops,
      showWheelchairModal,
      showWheelchairTypeSelector,
      showSearch,
      valid,
      stopState,
      search,
      fares,
      stops,
      error,
      passengersText,
      expandPassengers: ref(false),
      countryFilter,
      euroTrip,
      ...toRefs(dataset)
    }
  }
})
